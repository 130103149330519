<template>
    <Layout>
        <PageHeader :items="items" :title="title" />

        <div class="profileSection">
            <ProfileAside />
            <div class="profileContent">
                <div class="profileContentInner">
                    <form @submit.prevent="submitForm()">
                        <div
                            class="popup-layout"
                            v-if="
                                (isVisibleModalProfileOnboarding &&
                                    isVisibleProfileOnboarding) ||
                                    this.$store.getters.getIsReviewOnboardProfile
                                        .isReviewOnboardProfile
                            "
                        ></div>
                        <div
                            :class="
                                `profileContentRow ${
                                    (isVisibleModalProfileOnboarding &&
                                        isVisibleProfileOnboarding) ||
                                    this.$store.getters.getIsReviewOnboardProfile
                                        .isReviewOnboardProfile
                                        ? 'profileOnboarding'
                                        : ''
                                }`
                            "
                        >
                            <OnboardModal
                                style="left: 0; top: calc(100% + 16px);"
                                v-if="
                                    (isVisibleModalProfileOnboarding &&
                                        isVisibleProfileOnboarding) ||
                                        this.$store.getters.getIsReviewOnboardProfile
                                            .isReviewOnboardProfile
                                "
                                currentStep="1"
                                totalSteps="1"
                                title='Раздел "Профиль"'
                                description="В этом разделе представлена информация о Вас."
                                :isNextButton="true"
                                nextButtonTitle="ЗАВЕРШИТЬ"
                                :onClose="() => showedDashboardOnboarding()"
                                :onCloseModal="() => showedDashboardOnboarding()"
                            />
                            <div class="profileContentCol">
                                <div class="profileBox">
                                    <p class="title1 profileBox__title">
                                        Персональные данные
                                    </p>

                                    <div class="profileAvatar">
                                        <img
                                            v-bind:src="
                                                `${this.$store.getters.getAvatar}`
                                            "
                                            alt="avatar"
                                            class="profileAvatar__img"
                                        />
                                        <input
                                            id="file"
                                            class="hidden"
                                            type="file"
                                            name="file"
                                            @change="onFileSelected($event)"
                                        />
                                        <ButtonWithAction
                                            class="profileAvatar__btn"
                                            extraClass="button--bordered"
                                            title="изменить"
                                            :isButton="true"
                                        />
                                    </div>

                                    <div class="form">
                                        <div class="formrow">
                                            <div class="formgroup">
                                                <label class="formgroup__label">
                                                    Имя
                                                    <Tooltip
                                                        class="nameTooltip tooltip tooltip--name"
                                                        :tooltipIcon="
                                                            require('@/assets/img/common/info.svg')
                                                        "
                                                        text="Введите Ваше настоящее имя, так как именно оно будет указано в сертификате"
                                                    />
                                                </label>
                                                <Input
                                                    :class="
                                                        isEmptyName ? 'phoneInput' : ''
                                                    "
                                                    type="text"
                                                    placeholder="Введите ваше имя"
                                                    name="firstName"
                                                    :inputValue="user.firstName"
                                                    :onChange="
                                                        (e) =>
                                                            onChangeSetValue(
                                                                e,
                                                                'firstName'
                                                            )
                                                    "
                                                />
                                                <span
                                                    class="h5 error errorText"
                                                    v-if="isEmptyName"
                                                >
                                                    Заполните поля перед сохранением
                                                </span>
                                            </div>
                                            <div class="formgroup">
                                                <label class="formgroup__label">
                                                    Фамилия
                                                    <Tooltip
                                                        class="nameTooltip tooltip tooltip--name"
                                                        :tooltipIcon="
                                                            require('@/assets/img/common/info.svg')
                                                        "
                                                        text="Введите Вашу настоящую фамилию, так как именно она будет указана в сертификате"
                                                    />
                                                </label>
                                                <Input
                                                    :class="
                                                        isEmptySurname ? 'phoneInput' : ''
                                                    "
                                                    type="text"
                                                    placeholder="Введите вашу фамилию"
                                                    name="lastName"
                                                    :inputValue="user.lastName"
                                                    :onChange="
                                                        (e) =>
                                                            onChangeSetValue(
                                                                e,
                                                                'lastName'
                                                            )
                                                    "
                                                />
                                                <span
                                                    class="h5 error errorText"
                                                    v-if="isEmptySurname"
                                                >
                                                    Заполните поля перед сохранением
                                                </span>
                                            </div>
                                        </div>
                                        <div class="formrow">
                                            <div class="formgroup">
                                                <label class="formgroup__label">
                                                    Email
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите ваш email"
                                                    name="email"
                                                    :inputValue="user.email"
                                                    :disabled="true"
                                                />
                                            </div>
                                            <div class="formgroup">
                                                <label class="formgroup__label">
                                                    Телефон
                                                </label>
                                                <phone-mask-input
                                                    :class="
                                                        isEmptyPhone ? 'phoneInput' : ''
                                                    "
                                                    v-model="user.phone"
                                                    placeholder="Введите ваш номер телефона"
                                                    autoDetectCountry
                                                    wrapperClass="wrraper-example"
                                                    inputClass="input-example"
                                                    flagClass="flag-example"
                                                    :onChange="
                                                        onChangeSetValue(
                                                            user.phone,
                                                            'phone'
                                                        )
                                                    "
                                                />
                                                <span
                                                    class="h5 error errorText"
                                                    v-if="isEmptyPhone"
                                                >
                                                    Заполните поля перед сохранением
                                                </span>
                                            </div>
                                        </div>
                                        <div class="formgroup">
                                            <label class="formgroup__label">
                                                Дата рождения
                                            </label>
                                            <div class="customDatepicker">
                                                <date-picker
                                                    v-model="bday"
                                                    type="date"
                                                    format="DD/MM/YYYY"
                                                    placeholder="__/__/____"
                                                    valueType="format"
                                                    append-to-body
                                                    lang="ru"
                                                    :onChange="
                                                        onChangeSetValue(bday, 'birthday')
                                                    "
                                                ></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="profileContentCol">
                                <div class="profileBox">
                                    <p class="title1 profileBox__title">
                                        Местоположение
                                    </p>

                                    <div class="form">
                                        <!--<div class="formgroup">
                                            <label class="formgroup__label">
                                                Индекс
                                            </label>
                                            <Input
                                                type="text"
                                                placeholder="Введите ваш индекс"
                                                name="ZIPCode"
                                                :inputValue="
                                                    user.address
                                                        ? user.address.ZIPCode
                                                        : ''
                                                "
                                                :onChange="
                                                    (e) =>
                                                        onChangeSetValue(
                                                            e,
                                                            'address.ZIPCode'
                                                        )
                                                "
                                            />
                                        </div>-->
                                        <div class="formgroup">
                                            <label class="formgroup__label">
                                                Страна
                                            </label>
                                            <ProfileLangSelect
                                                :userData="
                                                    user &&
                                                    user.address &&
                                                    !!user.address.country
                                                        ? user.address.country
                                                        : 'UA'
                                                "
                                                :onSelectItem="
                                                    (event) =>
                                                        onChangeSetValue(
                                                            event,
                                                            'address.country'
                                                        )
                                                "
                                                :options="newCountries[0]"
                                            />
                                        </div>
                                        <div class="formgroup">
                                            <label class="formgroup__label">
                                                Область
                                            </label>
                                            <Input
                                                :class="isEmptyState ? 'phoneInput' : ''"
                                                type="text"
                                                placeholder="Введите вашу область"
                                                name="address"
                                                :inputValue="
                                                    user &&
                                                    user.address &&
                                                    user.address.state
                                                        ? user.address.state
                                                        : ''
                                                "
                                                :onChange="
                                                    (e) =>
                                                        onChangeSetValue(
                                                            e,
                                                            'address.state'
                                                        )
                                                "
                                            />
                                            <span
                                                class="h5 error errorText"
                                                v-if="isEmptyState"
                                            >
                                                Заполните поля перед сохранением
                                            </span>
                                        </div>
                                        <div class="formgroup">
                                            <label class="formgroup__label">
                                                Город
                                            </label>
                                            <Input
                                                :class="isEmptyCity ? 'phoneInput' : ''"
                                                type="text"
                                                placeholder="Введите ваш город"
                                                name="city"
                                                :inputValue="
                                                    user &&
                                                    user.address &&
                                                    user.address.city
                                                        ? user.address.city
                                                        : ''
                                                "
                                                :onChange="
                                                    (e) =>
                                                        onChangeSetValue(
                                                            e,
                                                            'address.city'
                                                        )
                                                "
                                            />
                                            <span
                                                class="h5 error errorText"
                                                v-if="isEmptyCity"
                                            >
                                                Заполните поля перед сохранением
                                            </span>
                                        </div>
                                        <!--<div class="formgroup">
                                            <label class="formgroup__label">
                                                Адрес проживания
                                            </label>
                                            <Input
                                                type="text"
                                                placeholder="Введите адрес"
                                                name="street"
                                                :inputValue="
                                                    user.address
                                                        ? user.address.street
                                                        : ''
                                                "
                                                :onChange="
                                                    (e) =>
                                                        onChangeSetValue(
                                                            e,
                                                            'address.street'
                                                        )
                                                "
                                            />
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ButtonWithAction
                            type="submit"
                            class="profileMainSubmit"
                            title="Сохранить"
                            :isButton="false"
                        />
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/views/layouts/Layout";
import Input from "@/views/components/Input/Input";
import Select from "@/views/components/Select/Select";
import Button from "@/views/components/Button/Button";
import ButtonWithAction from "@/views/components/ButtonWithAction/Button";
import Tooltip from "@/views/components/Tooltip/Tooltip";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ProfileAside from "@/views/partials/ProfileAside/ProfileAside";
import Alert from "@/views/partials/Alert/Alert";
import ProfileLangSelect from "@/views/components/ProfileLangSelect/ProfileLangSelect";
import countries from "../../../../countries.json";
import PhoneMaskInput from "vue-phone-mask-input";
import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

import "vue2-datepicker/locale/ru";
import "vue2-datepicker/index.css";

export default {
    name: "profileMain",

    components: {
        Layout,
        PageHeader,
        ProfileAside,
        Input,
        Select,
        Button,
        ButtonWithAction,
        Tooltip,
        DatePicker,
        Alert,
        ProfileLangSelect,
        PhoneMaskInput,
        OnboardModal,
    },

    async beforeMount() {
        await this.updateProfileData();
        this.user = this.$store.getters.getUserProfile.user;
        if (!(this.user && this.user.address)) {
            this.user.address = {
                country: "",
                city: "",
                state: "",
            };
        }
        if (!(this.user && this.user.address && this.user.address.country)) {
            this.user.address.country = "Украина";
        }
        this.bday = this.$store.getters.getUserProfile.user_date;

        if (
            (await this.isVisibleModalProfileOnboarding) ||
            (await this.$store.getters.getIsReviewOnboardProfile.isReviewOnboardProfile)
        ) {
            {
                window.scrollTo({
                    top: 99999999,
                    behavior: "smooth",
                });
            }
        }
    },

    created() {
        this.$root.$on("buttonClickEvent", async (event) => {
            await this.uploadFile(event);
        });
    },

    data() {
        return {
            user: "",
            bday: "",
            title: "Профиль",
            // isDisabledButton: true,
            items: [
                {
                    text: "Магазин программ",
                    to: "/",
                },
                {
                    text: "Профиль",
                    active: true,
                },
            ],
            newCountries: [],
            isEmptyPhone: false,
            isEmptyName: false,
            isEmptySurname: false,
            isEmptyState: false,
            isEmptyCity: false,

            isVisibleProfileOnboarding: true,

            isReviewOnboardProfile: this.$store.getters.getIsReviewOnboardProfile
                .isReviewOnboardProfile,
        };
    },

    computed: {
        isVisibleModalProfileOnboarding: function() {
            return (
                this.$store.getters.getUserProfile &&
                this.$store.getters.getUserProfile.user &&
                !this.$store.getters.getUserProfile.user.viewedOnBoardingProfile
            );
        },
    },

    methods: {
        uploadFile() {
            document.getElementById("file").click();
        },

        async onFileSelected(event) {
            const file = event.target.files[0];
            if (file["type"].includes("image")) {
                if (file.size <= 1024 * 1024 * 2) {
                    let data = new FormData();
                    data.append("name", file.name);
                    data.append("file", file);

                    const response = await this.axios.post("/profile/upload_image", data);
                    if (response && response.data && response.data.result) {
                        document.getElementById("file").value = "";

                        await this.$store.dispatch(
                            "setAvatar",
                            this.$apiUrl.slice(0, -1) + response.data.photoPath
                        );

                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "success",
                                text: "Изображение загружено",
                            },
                        });
                    } else {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text:
                                    (response && response.data && response.data.error) ||
                                    "Ошибка при загрузке файла",
                            },
                        });
                    }
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Размер файла не должен превышать 2 Мб",
                        },
                    });
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Не подходящий тип файла",
                    },
                });
            }
        },

        submitForm: async function() {

            let isValidForm = true;

            if (this.user && this.user.firstName === "") {
                isValidForm = false;
                this.isEmptyName = true;
            }

            if (this.user && this.user.lastName === "") {
                isValidForm = false;
                this.isEmptySurname = true;
            }

            if (this.user && this.user.phone === "") {
                isValidForm = false;
                this.isEmptyPhone = true;
            }

            if (this.user && this.user.address && this.user.address.city === "") {
                isValidForm = false;
                this.isEmptyCity = true;
            }

            if (this.user && this.user.address && this.user.address.state === "") {
                isValidForm = false;
                this.isEmptyState = true;
            }

            if (this.user && this.user.address && this.user.address.country === "") {
                isValidForm = false;
            }

            if (isValidForm) {
                this.isEmptyPhone = false;
                this.isEmptyName = false;
                this.isEmptySurname = false;
                this.isEmptyState = false;
                this.isEmptyCity = false;
                const saveProfileResult = await this.axios.post(
                    "/profile/edit",
                    this.user
                );
                if (
                    saveProfileResult &&
                    saveProfileResult.data &&
                    saveProfileResult.data.result
                ) {
                    // this.isDisabledButton = true;

                    await this.$store.dispatch(
                        "setName",
                        saveProfileResult.data.user.firstName
                    );

                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Сохранено",
                        },
                    });
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при сохранении профиля",
                        },
                    });
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Заполните поля перед сохранением",
                    },
                });
            }
        },

        onChangeSetValue: function(value, key) {
            if (key.includes(".")) {
                this.user[key.split(".")[0]][key.split(".")[1]] = value;
            } else {
                this.user[key] = value;
            }
        },

        async showedDashboardOnboarding() {
            if (
                this.isVisibleModalProfileOnboarding &&
                this.$store.getters.getIsReviewOnboardProfile.isReviewOnboardProfile
            ) {
                await this.axios.post("/viewedOnBoardingProfile");
                this.isVisibleProfileOnboarding = false;
                this.updateProfileData();
                this.$store.dispatch("setIsReviewOnBoardProfile", false);
            } else {
                if (this.isVisibleModalProfileOnboarding) {
                    await this.axios.post("/viewedOnBoardingProfile");
                    this.isVisibleProfileOnboarding = false;
                    this.updateProfileData();
                } else {
                    this.$store.dispatch("setIsReviewOnBoardProfile", false);
                    await this.$router.push(
                        "/" + "trejding_ot_a_do_ya_3_5" + "/dashboard"
                    );
                }
            }
        },

        async updateProfileData() {
            const getProfileDataResult = await this.axios.get("/profile/edit");
            if (
                getProfileDataResult &&
                getProfileDataResult.data &&
                getProfileDataResult.data.result
            ) {
                await this.$store.dispatch("setUserProfile", getProfileDataResult.data);
            } else {
                await this.$store.dispatch("clearUserProfile");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке профиля",
                    },
                });
            }
        },
    },

    mounted() {
        this.newCountries.push(
            Object.keys(countries).map((item) => ({ text: countries[item], value: item }))
        );
    },
};
</script>

<style lang="scss" scoped>
@import "profileMain.scss";
</style>
