<template>
    <div class="defaultSelect">
        <div class="input defaultSelect__langSelect" @click="() => onClickListActive()">
            {{ selectedOption }}
            <img
                :class="isListActive ? 'defaultSelect__imgRotate' : 'defaultSelect__img'"
                src="@/assets/img/common/selectDropdown.svg"
            />
        </div>

        <div v-if="this.isListActive" class="defaultSelectOptions customScroll">
            <div v-for="(item, index) in options" :key="index" @click="() => hideMenu()">
                <span
                    :class="
                        item.text === selectedOption
                            ? 'text2 colorWhite defaultSelectOption defaultSelectOption--active'
                            : 'text2 colorBlack defaultSelectOption'
                    "
                    @click="() => setSelectedOption(item.text, item.value)"
                    >{{ item.text }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "ProfileLangSelect",

    components: {
        Button,
    },

    data() {
        return {
            isListActive: false,
            selectedField: {},
            selectedOption: this.options.find((item) => item.value === this.userData)
                ? this.options.find((item) => item.value === this.userData).text
                : "Украина",
        };
    },

    props: {
        onSelectItem: Function,
        userData: String,
        options: [
            {
                text: String,
                value: String,
            },
        ],
    },

    methods: {
        hideMenu() {
            this.isListActive = false;
        },

        onClickListActive() {
            this.isListActive = !this.isListActive;
        },

        setSelectedOption(value, key) {
            this.selectedOption = value;
            this.onSelectItem(key);
        },
    },

    mounted() {
        this.selectedOption;
    },
};
</script>

<style lang="scss" scoped>
@import "ProfileLangSelect.scss";
</style>
