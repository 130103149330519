var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"items":_vm.items,"title":_vm.title}}),_c('div',{staticClass:"profileSection"},[_c('ProfileAside'),_c('div',{staticClass:"profileContent"},[_c('div',{staticClass:"profileContentInner"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[(
                            (_vm.isVisibleModalProfileOnboarding &&
                                _vm.isVisibleProfileOnboarding) ||
                                this.$store.getters.getIsReviewOnboardProfile
                                    .isReviewOnboardProfile
                        )?_c('div',{staticClass:"popup-layout"}):_vm._e(),_c('div',{class:("profileContentRow " + ((_vm.isVisibleModalProfileOnboarding &&
                                    _vm.isVisibleProfileOnboarding) ||
                                this.$store.getters.getIsReviewOnboardProfile
                                    .isReviewOnboardProfile
                                    ? 'profileOnboarding'
                                    : ''))},[(
                                (_vm.isVisibleModalProfileOnboarding &&
                                    _vm.isVisibleProfileOnboarding) ||
                                    this.$store.getters.getIsReviewOnboardProfile
                                        .isReviewOnboardProfile
                            )?_c('OnboardModal',{staticStyle:{"left":"0","top":"calc(100% + 16px)"},attrs:{"currentStep":"1","totalSteps":"1","title":"Раздел \"Профиль\"","description":"В этом разделе представлена информация о Вас.","isNextButton":true,"nextButtonTitle":"ЗАВЕРШИТЬ","onClose":function () { return _vm.showedDashboardOnboarding(); },"onCloseModal":function () { return _vm.showedDashboardOnboarding(); }}}):_vm._e(),_c('div',{staticClass:"profileContentCol"},[_c('div',{staticClass:"profileBox"},[_c('p',{staticClass:"title1 profileBox__title"},[_vm._v(" Персональные данные ")]),_c('div',{staticClass:"profileAvatar"},[_c('img',{staticClass:"profileAvatar__img",attrs:{"src":("" + (this.$store.getters.getAvatar)),"alt":"avatar"}}),_c('input',{staticClass:"hidden",attrs:{"id":"file","type":"file","name":"file"},on:{"change":function($event){return _vm.onFileSelected($event)}}}),_c('ButtonWithAction',{staticClass:"profileAvatar__btn",attrs:{"extraClass":"button--bordered","title":"изменить","isButton":true}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"formrow"},[_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Имя "),_c('Tooltip',{staticClass:"nameTooltip tooltip tooltip--name",attrs:{"tooltipIcon":require('@/assets/img/common/info.svg'),"text":"Введите Ваше настоящее имя, так как именно оно будет указано в сертификате"}})],1),_c('Input',{class:_vm.isEmptyName ? 'phoneInput' : '',attrs:{"type":"text","placeholder":"Введите ваше имя","name":"firstName","inputValue":_vm.user.firstName,"onChange":function (e) { return _vm.onChangeSetValue(
                                                            e,
                                                            'firstName'
                                                        ); }}}),(_vm.isEmptyName)?_c('span',{staticClass:"h5 error errorText"},[_vm._v(" Заполните поля перед сохранением ")]):_vm._e()],1),_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Фамилия "),_c('Tooltip',{staticClass:"nameTooltip tooltip tooltip--name",attrs:{"tooltipIcon":require('@/assets/img/common/info.svg'),"text":"Введите Вашу настоящую фамилию, так как именно она будет указана в сертификате"}})],1),_c('Input',{class:_vm.isEmptySurname ? 'phoneInput' : '',attrs:{"type":"text","placeholder":"Введите вашу фамилию","name":"lastName","inputValue":_vm.user.lastName,"onChange":function (e) { return _vm.onChangeSetValue(
                                                            e,
                                                            'lastName'
                                                        ); }}}),(_vm.isEmptySurname)?_c('span',{staticClass:"h5 error errorText"},[_vm._v(" Заполните поля перед сохранением ")]):_vm._e()],1)]),_c('div',{staticClass:"formrow"},[_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Email ")]),_c('Input',{attrs:{"type":"text","placeholder":"Введите ваш email","name":"email","inputValue":_vm.user.email,"disabled":true}})],1),_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Телефон ")]),_c('phone-mask-input',{class:_vm.isEmptyPhone ? 'phoneInput' : '',attrs:{"placeholder":"Введите ваш номер телефона","autoDetectCountry":"","wrapperClass":"wrraper-example","inputClass":"input-example","flagClass":"flag-example","onChange":_vm.onChangeSetValue(
                                                        _vm.user.phone,
                                                        'phone'
                                                    )},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),(_vm.isEmptyPhone)?_c('span',{staticClass:"h5 error errorText"},[_vm._v(" Заполните поля перед сохранением ")]):_vm._e()],1)]),_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Дата рождения ")]),_c('div',{staticClass:"customDatepicker"},[_c('date-picker',{attrs:{"type":"date","format":"DD/MM/YYYY","placeholder":"__/__/____","valueType":"format","append-to-body":"","lang":"ru","onChange":_vm.onChangeSetValue(_vm.bday, 'birthday')},model:{value:(_vm.bday),callback:function ($$v) {_vm.bday=$$v},expression:"bday"}})],1)])])])]),_c('div',{staticClass:"profileContentCol"},[_c('div',{staticClass:"profileBox"},[_c('p',{staticClass:"title1 profileBox__title"},[_vm._v(" Местоположение ")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Страна ")]),_c('ProfileLangSelect',{attrs:{"userData":_vm.user &&
                                                _vm.user.address &&
                                                !!_vm.user.address.country
                                                    ? _vm.user.address.country
                                                    : 'UA',"onSelectItem":function (event) { return _vm.onChangeSetValue(
                                                        event,
                                                        'address.country'
                                                    ); },"options":_vm.newCountries[0]}})],1),_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Область ")]),_c('Input',{class:_vm.isEmptyState ? 'phoneInput' : '',attrs:{"type":"text","placeholder":"Введите вашу область","name":"address","inputValue":_vm.user &&
                                                _vm.user.address &&
                                                _vm.user.address.state
                                                    ? _vm.user.address.state
                                                    : '',"onChange":function (e) { return _vm.onChangeSetValue(
                                                        e,
                                                        'address.state'
                                                    ); }}}),(_vm.isEmptyState)?_c('span',{staticClass:"h5 error errorText"},[_vm._v(" Заполните поля перед сохранением ")]):_vm._e()],1),_c('div',{staticClass:"formgroup"},[_c('label',{staticClass:"formgroup__label"},[_vm._v(" Город ")]),_c('Input',{class:_vm.isEmptyCity ? 'phoneInput' : '',attrs:{"type":"text","placeholder":"Введите ваш город","name":"city","inputValue":_vm.user &&
                                                _vm.user.address &&
                                                _vm.user.address.city
                                                    ? _vm.user.address.city
                                                    : '',"onChange":function (e) { return _vm.onChangeSetValue(
                                                        e,
                                                        'address.city'
                                                    ); }}}),(_vm.isEmptyCity)?_c('span',{staticClass:"h5 error errorText"},[_vm._v(" Заполните поля перед сохранением ")]):_vm._e()],1)])])])],1),_c('ButtonWithAction',{staticClass:"profileMainSubmit",attrs:{"type":"submit","title":"Сохранить","isButton":false}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }